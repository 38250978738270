// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
import Rails from "@rails/ujs";
window.Rails = Rails;

import $ from 'jquery';
import 'lazysizes';
import select2 from 'select2';
import Swiper, { Navigation } from 'swiper';
Swiper.use([Navigation]);

$(document).ready(function() {
  var mySwiper = new Swiper('.swiper-container', {
      loop: true,
      speed: 400,
      spaceBetween: 100,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
  });

  $(".js-example-tags").select2({
    tags: true,
    minimumInputLength: 2,
    placeholder: "State",
    language: {
      inputTooShort: function() {
        return 'Search States';
      }
    }
  });
});



// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
